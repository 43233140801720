import { DeleteOutlined } from '@ant-design/icons';
import { Select, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import { useEventsList } from '../api/useEventsList';
import { ImagesHelpers } from '../helpers/images';
import SortableList from './SortableList';

type Props = { value?: [], onChange?: (value: any) => void, query?: any }
const EventsManagementInput = ({ value = [], onChange = () => { }, query }: Props) => {
    const [data, loading]: [any, any] = useEventsList(query);
    const [currentV, setCurrentcurrentV] = useState(undefined);
    const onAdd = (item: any) => {
        setCurrentcurrentV(item);
        onChange([...value, parseInt(item)]);
        //@ts-ignore
        setCurrentcurrentV(null);
    }
    const findItem = (item: any) => {
        let found = data.find((i: any) => i._id === parseInt(item.id));
        if (found)
            return found;
        return {};
    };
    const SliderItem = (item: any) => {
        return (<div style={{ width: 100, height: 150, marginBottom: 8, position: 'relative', marginRight: 10 }}>
            <Typography.Paragraph ellipsis>
                {findItem(item).title}
            </Typography.Paragraph>
            <img style={{ width: "100%", height: 100, objectFit: 'cover' }} src={ImagesHelpers.formatUrl(findItem(item).image)} alt="slider" />
            <div style={{ textAlign: 'center' }}>
                <DeleteOutlined onClick={() => {
                    onChange(value.filter((i: any) => parseInt(i) !== parseInt(item.id)));
                }} style={{ fontSize: '13px', cursor: 'pointer', }} />
            </div>
        </div>)
    }
    if (loading)
        return <Skeleton />
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <div style={{ marginBottom: 10 }}>
                <Select placeholder="Select event to add " style={{ width: 300, marginTop: 10 }} showSearch optionFilterProp="children" autoClearSearchValue value={currentV} onChange={onAdd} >
                    {data.filter((i: any) => {
                        if (i.slug === "sculptures")
                            return false;
                        return !value.find((v: any) => parseInt(v) === i._id)
                    }).map((i: any) => (
                        <Select.Option value={i._id} key={i._id}>{i.title}</Select.Option>
                    ))}
                </Select>

            </div>
            <SortableList horizontal renderItem={SliderItem} list={value.map((i, index) => ({ index: index, id: i + "" }))} onChange={onChange} />


        </div>
    )
};

export default EventsManagementInput;