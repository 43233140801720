import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { ImagesHelpers } from '../helpers/images';
import SliderFormModal from './SliderFormModal';
import SliderSortableList from './SliderSortableList';

type Props = { value?: [], onChange?: (value: any) => void }
const SliderManagementInput = ({ value = [], onChange = () => { } }: Props) => {
    const onAdd = (item: any) => {
        onChange([...value, { ...item, index: value.length, id: `${value.length}` }]);
    }
    const onEdit = (item: any) => {
        let index = value.findIndex((i: any) => i.id === item.id);
        let clone: any = [...value];
        clone[index] = item;
        onChange(clone);
    };
    const SliderItem = (item: any) => {
        return (<div style={{ width: 100, height: 150, marginBottom: 8, position: 'relative', marginRight: 10 }}>
            <img style={{ width: "100%", height: 100, objectFit: 'cover' }} src={ImagesHelpers.formatUrl(item.image)} alt="slider" />
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', marginTop: 5 }}>
                <DeleteOutlined onClick={() => {
                    onChange(value.filter((i: any) => i.id !== item.id));
                }} style={{ fontSize: '13px', cursor: 'pointer', marginRight: 5 }} />
                <SliderFormModal value={item} onChange={onEdit} button={(onClick: any) => {
                    return (<EditOutlined onClick={onClick} style={{ fontSize: '13px', cursor: 'pointer', }} />)
                }} />
            </div>
        </div>)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <div style={{ marginBottom: 10 }}>
                <SliderFormModal onChange={onAdd} button={(onClick: any) => {
                    return (<Button type="primary" onClick={onClick}>Add Slide<PlusOutlined /></Button>)
                }} />
            </div>
            <SliderSortableList horizontal renderItem={SliderItem} list={value} onChange={onChange} />

        </div>
    )
};

export default SliderManagementInput;