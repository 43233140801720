import { Button, Col, Input, message, Result, Row, Space, Select } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import { Venue } from "../types/venue";
import { useCountriesList } from "../hooks/useCountriesList";
import PhoneArrayField from "./PhoneArrayField";
import EmailArrayField from "./EmailsArrayField";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined, GlobalOutlined } from "@ant-design/icons";


const VenueForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm<Venue>();
    const [countries, countriesLoading] = useCountriesList();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/venues')
    }
    const imageFields = [
        {
            name: "image",
            singleValue: true,
        },
        {
            name: "imageGallery",
            singleValue: false,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.venues.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.venues.create,
            updateApiCall: Api.venues.update,
            onSuccess,
            imageFields,
            updateId: id,
            defaultValues: { phoneNumbers: [] }
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/venues">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"name"}>Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"name"} />}
                            rules={{ required: true }}
                            name="name"
                        />
                        <FieldErrorMessage errors={errors} name="name" />
                    </Col>

                </Row>
                <Row gutter={6}>
                    <Col span={12}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>
                    <Col span={12}>
                        <label htmlFor={"imageGallery"}>Image Gallery</label>
                        <Controller
                            control={control}
                            as={<FileUploader limit={10} />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="imageGallery"
                        />
                        <FieldErrorMessage errors={errors} name="imageGallery" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"country"}>Country</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={countries.map(i => ({ label: i.name, value: i._id, key: i._id }))} id={"countries"} />}
                            rules={{ required: true }}
                            name="country"
                        />
                        <FieldErrorMessage errors={errors} name="country" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"coordinates.latitude"}>Latitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"coordinates.latitude"} />}
                            rules={{ required: false }}
                            name="coordinates.latitude"
                        />
                        <FieldErrorMessage errors={errors} name="coordinates.latitude" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"coordinates.longitude"}>Longitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"coordinates.longitude"} />}
                            rules={{ required: false }}
                            name="coordinates.longitude"
                        />
                        <FieldErrorMessage errors={errors} name="coordinates.longitude" />
                    </Col>

                    <Col span={6}>
                        <label htmlFor={"address"}>Address</label>
                        <Controller
                            control={control}
                            as={<Input id={"address"} />}
                            rules={{ required: false }}
                            name="address"
                        />
                        <FieldErrorMessage errors={errors} name="address" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <PhoneArrayField control={control} errors={errors} fieldName="phoneNumbers" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"website"}>Website</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<GlobalOutlined />} id={"website"} />}
                            rules={{ required: false }}
                            name="website"
                        />
                        <FieldErrorMessage errors={errors} name="website" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMediaUrls.facebook"}>Facebook</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<FacebookOutlined />} id={"socialMedia.facebook"} />}
                            rules={{ required: false }}
                            name="socialMedia.facebook"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.facebook" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.instagram"}>Instagram</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<InstagramOutlined />} id={"socialMedia.instagram"} />}
                            rules={{ required: false }}
                            name="socialMedia.instagram"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.instagram" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.twitter"}>Twitter</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<TwitterOutlined />} id={"socialMedia.twitter"} />}
                            rules={{ required: false }}
                            name="socialMedia.twitter"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.twitter" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.youtube"}>Youtube</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<YoutubeOutlined />} id={"socialMedia.youtube"} />}
                            rules={{ required: false }}
                            name="socialMedia.youtube"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.youtube" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"description"}>Description</label>
                        <Controller
                            control={control}
                            as={<Input.TextArea rows={4} id={"description"} />}
                            rules={{ required: false }}
                            name="description"
                        />
                        <FieldErrorMessage errors={errors} name="description" />
                    </Col>

                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default VenueForm;