import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col, Input, Button, Checkbox, Space, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { FieldErrorMessage } from './FieldErrorMessage';
import { Ad_Placements } from './AdvertisementForm';
import { useCountriesList } from '../hooks/useCountriesList';


export type AdvertismenetTableFilterProp = {
    value: any,
    onChange: (value: any) => void,
    filterDefinitions?: any,
    deletedFilter?: boolean,
}
const default_definitions = [
    {
        key: '_id',
        type: 'number',
        label: "ID",
        span: 3,
    },
    {
        key: 'placement',
        type: 'string',
        label: "Placement",
        span: 3,
    },
    {
        key: 'country',
        type: 'string',
        label: "Country",
        span: 3,
    },
];
const AdvertismenetFilter = ({ value, onChange, filterDefinitions, deletedFilter = true }: AdvertismenetTableFilterProp) => {
    const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
    const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);
    const [countries, loadingCountries] = useCountriesList();
    useEffect(() => {
        if (deletedFilter)
            setDefinitions([
                ...definitions,
                {
                    key: 'showDeleted',
                    type: 'showDeleted',
                    excludeFromGenerator: true,
                }
            ])
    }, [deletedFilter]);
    const clearFilter = () => {
        reset({ showDeleted: false });
        onChange({
            filter: {},
            definitions,
        })
    }

    const handleChange = useCallback((v: any) => {
        console.log(v);
        onChange({
            filter: v,
            definitions,
        })
    }, [onChange]);
    // const call = useCallback(() => {
    //     reset(value);
    // }, [value, reset]);
    // useEffect(() => {
    //     call();
    // }, [value, call])
    const watchAllFields = watch("showDeleted");


    useEffect(() => {
        if (watchAllFields !== undefined) {
            handleSubmit(handleChange)();
        }
    }, [watchAllFields]);
    return (
        <div className="white-container" style={{ marginBottom: 12 }}>
            <form onSubmit={handleSubmit(handleChange)}>
                <Space size="large" style={{ width: "100%" }} direction="vertical">
                    <Row style={{ width: "100%" }} gutter={12}>
                        <Col span={4}  >
                            <div className="custom-form-item">
                                <label className="mr-10" htmlFor={"_id"}>ID: </label>
                                <Controller
                                    control={control}
                                    as={<Input id={"_id"} />}
                                    name="_id"
                                />
                            </div>
                            <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name="_id" formatName="ID" />
                        </Col>
                        <Col span={8}  >
                            <div className="custom-form-item">
                                <label className="mr-10" htmlFor={"name"}>Placement</label>
                                <Controller
                                    control={control}
                                    as={<Select allowClear style={{ width: "100%" }} options={Ad_Placements} id={"placement"} />}
                                    name="placement"
                                />
                            </div>
                            <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name="placement" formatName="Placement" />
                        </Col>
                        <Col span={8}  >
                            <div className="custom-form-item">
                                <label className="mr-10" htmlFor={"country"}>Country</label>
                                <Controller
                                    control={control}
                                    as={<Select allowClear style={{ width: "100%" }} options={countries.map(c => ({ label: c.name, value: c._id }))} id={"country"} />}
                                    name="country"
                                />
                            </div>
                            <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name="country" formatName="Country" />
                        </Col>





                    </Row>
                    <Row justify="end">
                        <Space size="small">
                            <div className="">
                                <label className="mr-10" htmlFor={"showDeleted"}>Show Deleted</label>
                                <Controller
                                    onChange={([e]: any) => e.target.checked}
                                    control={control}
                                    valueName="checked"
                                    as={Checkbox}
                                    name="showDeleted"
                                />
                            </div>
                            <Button htmlType="submit" size="small" type="dashed">Submit</Button>
                            <Button size="small" onClick={clearFilter} danger>Reset</Button>
                        </Space>
                    </Row>
                </Space>
            </form>
        </div >
    )
}

export default AdvertismenetFilter;