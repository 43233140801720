import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { Helpers } from '../helpers';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/guideCategories/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
      {
    title: 'CreatedBy',
    dataIndex: 'createdBy',
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
    key: "createdBy",
    sorter: true,
    width: '20%',
  },
  {
    title: 'updatedBy',
    dataIndex: 'updatedBy',
    sorter: true,
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
  },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        width: '20%',
        sorter: true,
        render: (date: Date) => {
            return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
        },
    },
];

const GuideCategoryTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={ "guideCategoriesTable" }
              deleteApiCall={Api.guideCategories.delete}
                allowDelete={true}
                restoreApiCall={Api.guideCategories.restore}
                actions={(record: any) => (
                    <Link to={`/guideCategories/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
             apiCall={Api.guideCategories.table} columns={columns} />
        </Space>
    )
};

export default GuideCategoryTable;