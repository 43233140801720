import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Space } from 'antd';
import React from 'react';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { FieldErrorMessage } from './FieldErrorMessage';


type ToRememberFormInputProps = {
    control: Control,
    fieldName?: string,
    errors: FieldErrors<any>,
};
const ToRememberFormInput: React.FC<ToRememberFormInputProps> = ({ control, fieldName = "toRemember", errors,}) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: fieldName,

    });

    return (<div>
        <Card bodyStyle={{ padding: 15 }} title="To Remember" >
            <Space style={{ width: "100%" }} direction="vertical">
                {fields.map((field, index) => (
                    <Row style={{ width: "100%" }} gutter={8} key={field.id}>
                        <Col span={11}>
                            <label htmlFor={`[${fieldName}[${index}].key`}>Key</label>
                            <Controller
                                control={control}
                                defaultValue={field.key}
                                as={<Input />}
                                name={`[${fieldName}[${index}].key`}
                            />
                            <FieldErrorMessage errors={errors} name={`[${fieldName}[${index}].key`} />
                        </Col>

                        <Col span={11}>
                            <label htmlFor={`[${fieldName}[${index}].value`}>Value</label>
                            <Controller
                                control={control}
                                defaultValue={field.value}
                                as={<Input />}
                                name={`[${fieldName}[${index}].value`}
                            />
                            <FieldErrorMessage errors={errors} name={`[${fieldName}[${index}].value`} />
                        </Col>
                        <Col style={{ marginTop: "auto" }} span={1}>
                            <Button onClick={() => { remove(index) }} icon={<DeleteOutlined />}></Button>
                        </Col>
                    </Row>
                ))}
                <Button style={{ width: "100%" }} icon={<PlusOutlined />} block onClick={() => {
                    append({ key: "", value: "" });
                }}>Add Value</Button>
            </Space>
        </Card>
    </div>);

}

export default ToRememberFormInput;