import React, { ReactChildren, useState, useEffect } from 'react';
import { Select } from 'antd';
import { Api } from '../api';
import { useTagsList } from '../hooks/useTagsList';

export const TagsInput = ({ children, ...args }: any) => {
    const [tags, loading] = useTagsList();
    const [tagsList, setTags] = useState(tags);

    useEffect(() => {
        setTags(tags);
    }, [tags])

    const handleSelect = (v: any) => {
        let found = tagsList.find((i: any) => i.name === v);
        if (!found)
            Api.tags.create({ name: v }).then(res => {
                setTags([...tagsList, res.data.data]);
            })
    }

    return (
        <Select style={{ width: "100%" }} {...args}
            loading={loading}
            onSelect={handleSelect}
            mode="tags"
            options={
                tagsList.map((i: any) => (
                    { label: i.name, value: i.name, key: i.name }
                ))
            }
        >
        </Select>
    )
}