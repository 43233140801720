import React, { useEffect } from 'react';
import { useFieldArray, Controller, Control, FieldErrors } from 'react-hook-form';
import { Col, Row, Input, Button, Space, Card, DatePicker } from 'antd';
import { FieldErrorMessage } from './FieldErrorMessage';
import Icon, { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

type PhoneArrayField = {
    control: Control,
    fieldName: string,
    errors: FieldErrors<any>
    setError: (name: string, value: any) => any,
    clearErrors: (args: any) => any,

};
const DateArrayField = ({ control, fieldName, errors, setError, clearErrors }: PhoneArrayField) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
        {
            control,

            name: fieldName,

        }
    );

    useEffect(() => {
        console.log(fields.length);
        if (!fields.length) {
            setError(fieldName, "required");
        }
        else
            clearErrors(fieldName);
    }, [fields])


    return (
        <Card bodyStyle={{ padding: 15 }} title="Dates" >
            <Space style={{ width: '100%' }} direction="vertical">
                {fields.map((item, index) => {
                    return (
                        <Row key={index} style={{ width: "100%" }} gutter={12}>
                            <Col span={24}>
                                <Input.Group size="default">
                                    <Row gutter={2}>
                                        <Col span={22}>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                as={({ onChange, onBlur, value }) => (
                                                    <DatePicker.RangePicker style={{ width: "100%" }}
                                                        format="DD-MM-YYYY"
                                                        onBlur={onBlur}
                                                        value={value ? [moment(value.from), moment(value.to)] : [null, null]}
                                                        onChange={(value) => {
                                                            if (value && value[0])
                                                                onChange({ from: value[0].toDate(), to: value[1]?.toDate() })
                                                        }}
                                                    />
                                                )}
                                                name={`${fieldName}[${index}]`}
                                            />
                                        </Col>

                                        <Col span={2}>
                                            <Button block style={{ height: "100%" }} onClick={() => { remove(index) }} icon={<DeleteOutlined />}></Button>
                                        </Col>
                                    </Row>
                                </Input.Group>
                                <FieldErrorMessage errors={errors} name={`${fieldName}[${index}]`} />
                            </Col>
                        </Row>


                    );
                })}
                <Button block onClick={() => { append({}) }} icon={<PlusOutlined />}></Button>
                <FieldErrorMessage errors={errors} name={fieldName} />
            </Space>

        </Card >
    )

};

export default DateArrayField;