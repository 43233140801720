import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Button, Space, Tag } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";
import { Helpers } from "../helpers";
import { ImagesHelpers } from "../helpers/images";
import AdvertismenetFilter from "./AdvertismenetTableFilter";
import EventsTableFilter from "./EventsTableFilter";
import DataExportButton from "./DataExportButton";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/events/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Image",
    sorter: false,
    dataIndex: "image",
    key: "image",
    render: (text: string) => {
      return (
        <img
          style={{ width: 50, height: 50, objectFit: "cover" }}
          src={ImagesHelpers.formatAmazonImage(text, { width: 200 })}
        />
      );
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: true,
  },
  {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (d: { name: string }[]) => {
      return d.map((c) => <Tag>{c.name}</Tag>);
    },
  },
  {
    title: "End Date",
    dataIndex: "date.to",
    key: "date",
    sorter: true,
    render: (d: any, row: any) => {
      return Helpers.formatDate(row.date[row.date.length - 1].to);
    },
  },
  {
    title: "Venue",
    dataIndex: "venue",
    key: "venue",
    render: (d: any) => {
      if (!d) return null;
      return <Link to={`/venues/update/${d._id}`}>{d.name}</Link>;
    },
  },
  {
    title: "CreatedBy",
    dataIndex: "createdBy",
    render: (user: any) => {
      if (!user) return null;
      return (
        <span>
          {user.firstName} {user.lastName}
        </span>
      );
    },
    key: "createdBy",
    sorter: true,
  },
  {
    title: "User Submitted",
    dataIndex: "meta",
    key: "meta",
    render: (d: any) => {
      if (d.submittedByUser) return "Yes";
      return "No";
    },
  },
  {
    title: "createdAt",
    dataIndex: "createdAt",
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
];

const default_definitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "title",
    type: "search",
    label: "Title",
  },
  {
    key: "title",
    type: "search",
    label: "Title",
  },
];
const EventTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"eventsTable"}
        deleteApiCall={Api.events.delete}
        allowDelete={true}
        restoreApiCall={Api.events.restore}
        // filterDefinitions={default_definitions}
        actions={(record: any) => <Link to={`/events/update/${record._id}`}>View</Link>}
        rowClassName={(record: any, index: any) => record?.meta?.submittedByUser && "highlighted"}
        Filter={EventsTableFilter}
        apiCall={Api.events.table}
        columns={columns}
      />
    </Space>
  );
};

export default EventTable;
