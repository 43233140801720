import React from 'react';
import {Link} from 'react-router-dom';
import {Table} from './Table';
import {Api} from '../api';
import {Space} from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import {Helpers} from '../helpers';


const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/donations/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'First Name',
        dataIndex: 'firstName',
        key: "firstName",
        sorter: true,
        width: '20%',
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: "lastName",
        sorter: true,
        width: '20%',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: "amount",
        sorter: true,
        width: '20%',
    },
    {
        title: 'phone',
        dataIndex: 'phone',
        key: "phone",
        sorter: true,
        width: '20%',
    },
    {
        title: 'email',
        dataIndex: 'email',
        key: "email",
        sorter: true,
        width: '20%',
    },
    {
        title: 'Is Completed',
        dataIndex: 'isCompleted',
        key: "isCompleted",
        sorter: true,
        width: '20%',
        render: (isCompleted: boolean) => {
            return (<p>{isCompleted ? "Yes" : "No"}</p>)
        }
    },
    {
        title: 'updatedBy',
        dataIndex: 'updatedBy',
        sorter: true,
        render: (user: any) => {
            if (!user)
                return (null);
            return (<span>{user.firstName} {user.lastName}</span>)
        },
    },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        width: '20%',
        sorter: true,
        render: (date: Date) => {
            return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
        },
    },
];

const DonationTable = () => {
    return (
        <Space style={{width: '100%'}} direction="vertical">
            <Table
                tableId={"donationsTable"}
                deleteApiCall={Api.donations.delete}
                allowDelete={true}
                restoreApiCall={Api.donations.restore}
                actions={(record: any) => (
                    <Link to={`/donations/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.donations.table} columns={columns}/>
        </Space>
    )
};

export default DonationTable;