import React from 'react';
//@ts-ignore
import CKEditor from '@ckeditor/ckeditor5-react';
//@ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Api } from '../api';
import { ImagesHelpers } from '../helpers/images';
import '../styles/reset.css';
class MyUploadAdapter {
    loader: any;
    constructor(loader: any) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader;

    }

    // Starts the upload process.
    async upload() {
        const file = await this.loader.file;
        var formData = new FormData();
        console.log(file)
        formData.append("file", file);
        return new Promise((resolve, reject) => {

            Api.upload({ formData })
                .then((res: any) => {
                    resolve({
                        default: ImagesHelpers.formatAmazonImage(res.data.path, { fullWidth: true }),
                        '500': ImagesHelpers.formatAmazonImage(res.data.path, { width: 500 }),
                        '1000': ImagesHelpers.formatAmazonImage(res.data.path, { width: 1000 }),
                        '1052': ImagesHelpers.formatAmazonImage(res.data.path, { width: 1052 }),
                    });
                })
                .catch((err: any) => {
                    console.log("here");
                    console.log(err);
                });
        });
    }

}




const TextEditorInput = ({ onChange, value }: any) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            style={{ height: 500 }}
            data={value}
            config={{
                height: 1000,
                mediaEmbed: {
                    previewsInData: false,
                },
                image: {
                    toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

                    styles: [
                        // This option is equal to a situation where no style is applied.
                        'full',

                        // This represents an image aligned to the left.
                        'alignLeft',

                        // This represents an image aligned to the right.
                        'alignRight'
                    ]
                }
            }}
            onInit={(editor: any) => {
                editor.plugins.get("FileRepository").createUploadAdapter = function (loader: any) {
                    return new MyUploadAdapter(loader);
                };
            }}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();
                onChange(data);
            }}

        />
    )
}

export default TextEditorInput;