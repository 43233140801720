import {Button, Col, Input, InputNumber, message, Result, Row, Space} from "antd";
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Link, useHistory, useParams} from "react-router-dom";
import {Api} from "../api";
import {useSubmitForm} from "../helpers/forms";
import {ImagesHelpers} from "../helpers/images";
import {useLoadFormData} from "../hooks/useLoadFormData";
import {FieldErrorMessage} from "./FieldErrorMessage";
import {FileUploader} from "./FileUploader";


const DonationForm = () => {
    let {id} = useParams();
    // const [notFound, setNotFound] = useState(false);
    const {handleSubmit, errors, control, reset} = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/donations')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.donations.single, id, setValue: reset,
        //  imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.donations.create,
            updateApiCall: Api.donations.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/donations">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{width: "100%"}} direction="vertical">
                <Row style={{width: "100%"}} gutter={12}>
                    <Col span={12}>
                        <label htmlFor={"firstName"}>First Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"firstName"}/>}
                            rules={{required: true}}
                            name="firstName"
                        />
                        <FieldErrorMessage errors={errors} name="firstName"/>
                    </Col>
                    <Col span={12}>
                        <label htmlFor={"lastName"}>Last Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"lastName"}/>}
                            rules={{required: true}}
                            name="lastName"
                        />
                        <FieldErrorMessage errors={errors} name="lastName"/>
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"amount"}>Amount</label>
                        <Controller
                            control={control}
                            as={<InputNumber id={"amount"}/>}
                            rules={{required: true}}
                            name="amount"
                        />
                        <FieldErrorMessage errors={errors} name="amount"/>
                    </Col>
                    <Col span={24}>
                        <label htmlFor={"message"}>Message</label>
                        <Controller
                            control={control}
                            as={<Input.TextArea id={"message"}/>}
                            rules={{required: false}}
                            name="message"
                        />
                        <FieldErrorMessage errors={errors} name="message"/>
                    </Col>
                    <Col span={12}>
                        <label htmlFor={"phone"}>Phone</label>
                        <Controller
                            control={control}
                            as={<Input id={"phone"}/>}
                            rules={{required: false}}
                            name="phone"
                        />
                        <FieldErrorMessage errors={errors} name="phone"/>
                    </Col>
                    <Col span={12}>
                        <label htmlFor={"email"}>Email</label>
                        <Controller
                            control={control}
                            as={<Input id={"email"}/>}
                            rules={{required: false}}
                            name="email"
                        />
                        <FieldErrorMessage errors={errors} name="email"/>
                    </Col>
                </Row>
            </Space>
            <div style={{marginBottom: 10}}/>
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default DonationForm;