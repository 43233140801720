import {message} from "antd";
import axios, {AxiosError, AxiosResponse} from "axios";
import {CONFIGURATION} from "../config";
import {Helpers} from "../helpers";
import {Redux_Actions, store} from "../store";

export const Base_Url = Helpers.isProduction
    ? CONFIGURATION.api_url_production
    : CONFIGURATION.simulateProduction
        ? CONFIGURATION.api_url_production
        : CONFIGURATION.api_url_dev;
const axiosInstance = axios.create({
    baseURL: Base_Url,
    timeout: 20 * 600000,
    withCredentials: false,
});
axiosInstance.interceptors.request.use(
    async (config) => {
        config.headers.Authorization = `Bearer ${store.getState().token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error: AxiosError) => {
        // message.error()
        if (error.response) {
            if (error.response.status === 401) {
                message.error("You have been logged out from the server");
                store.dispatch({type: Redux_Actions.logout});
            }
            if (error.response.data) {
                let err = error.response.data.message;
                if (err) {
                    if (Array.isArray(err) && error.response.status === 400) {
                        err.forEach((i) => {
                            message.error(i.msg);
                        });
                    } else if (err) {
                        message.error(err);
                    }
                } else message.error(error.toString());
            } else {
                message.error(error.toString());
            }
        } else {
            message.error(error.toString());
        }

        return Promise.reject(error);
    }
);

export let Api = {
    upload: ({formData}: any): Promise<AxiosResponse> =>
        axiosInstance.post("/file/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
    login: ({username, password}: { username: string; password: string }): Promise<any> =>
        axiosInstance.post("/login", {
            email: username,
            password,
        }),
    logout: (): Promise<AxiosResponse> => axiosInstance.get("/logout"),
    websiteusers: {
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/websiteusers/table", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/websiteusers/single/${id}`),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/websiteusers/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/websiteusers/restore/${id}`),
        toggleAllowToPost: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/websiteusers/toggleAllowedToPost/${id}`),
    },
    users: {
        create: ({...args}: { args: any }): Promise<AxiosResponse> =>
            axiosInstance.post("/users/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/users/table", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/users/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/users/update/${id}`, {...args}),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/users/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/users/restore/${id}`),
        resetPassword: ({id, args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/users/reset/${id}`, {...args}),
    },
    categories: {
        create: ({...args}: { args: any }): Promise<AxiosResponse> =>
            axiosInstance.post("/categories/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/categories/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/categories/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/categories/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/categories/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/categories/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/categories/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/categories/restore/${id}`),
    },
    contacts: {
        create: ({...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.post(`/contacts/create`, args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/contacts/table", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/contacts/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/contacts/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/contacts/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/contacts/restore/${id}`),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/contacts/list"),
    },

    tags: {
        create: ({...args}: any): Promise<AxiosResponse> => axiosInstance.post("/tags/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/tags/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/tags/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/tags/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/tags/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/tags/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/tags/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/tags/restore/${id}`),
    },
    donations: {
        create: ({...args}: any): Promise<AxiosResponse> => axiosInstance.post("/donations/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/donations/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/donations/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/donations/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/donations/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/tags/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/donations/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/donations/restore/${id}`),
    },
    pages: {
        create: ({...args}: any): Promise<AxiosResponse> => axiosInstance.post("/pages/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/pages/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/pages/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/pages/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/pages/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/pages/update/${id}`, {
                ...args,
            }),
    },
    contactForms: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/contact-form/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/contact-form/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/contact-form/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/contact-form/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/contact-form/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/contact-form/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/contact-form/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/contact-form/restore/${id}`),
    },
    countries: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/countries/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/countries/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/countries/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/countries/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/countries/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/countries/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/countries/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/countries/restore/${id}`),
    },
    venues: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/venues/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/venues/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/venues/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/venues/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/venues/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/venues/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/venues/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/venues/restore/${id}`),
    },
    events: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/events/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/events/table", {params: args}),
        export: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/events/export", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/events/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/events/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/events/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/events/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/events/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/events/restore/${id}`),
    },
    articles: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/articles/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/articles/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/articles/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/articles/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/articles/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/articles/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/articles/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/articles/restore/${id}`),
    },
    emailSubscriptions: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/emailSubscriptions/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/emailSubscriptions/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/emailSubscriptions/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/emailSubscriptions/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> =>
            axiosInstance.get(`/emailSubscriptions/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/emailSubscriptions/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/emailSubscriptions/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/emailSubscriptions/restore/${id}`),
    },
    eventSubmissions: {
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/eventSubmission/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/eventSubmission/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/eventSubmission/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> =>
            axiosInstance.get(`/eventSubmission/single/${id}`),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/eventSubmission/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/eventSubmission/restore/${id}`),
        accept: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/eventSubmission/accept/${id}`),
        reject: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/eventSubmission/reject/${id}`),
    },
    advertisements: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/diaayet/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/diaayet/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/diaayet/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/diaayet/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/diaayet/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/diaayet/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/diaayet/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/diaayet/restore/${id}`),
    },
    guideCategories: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/guideCategories/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/guideCategories/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/guideCategories/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/guideCategories/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> =>
            axiosInstance.get(`/guideCategories/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/guideCategories/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/guideCategories/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/guideCategories/restore/${id}`),
    },
    professions: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/professions/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/professions/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/professions/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/professions/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/professions/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/professions/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/professions/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/professions/restore/${id}`),
    },
    institutions: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/institutions/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/institutions/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/institutions/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/institutions/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/institutions/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/institutions/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/institutions/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/institutions/restore/${id}`),
    },
    fields: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/fields/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/fields/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/fields/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/fields/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/fields/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/fields/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/fields/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/fields/restore/${id}`),
    },
    guideEntries: {
        create: ({...args}: any): Promise<AxiosResponse> =>
            axiosInstance.post("/guideEntries/create", args),
        table: (args: any): Promise<AxiosResponse> =>
            axiosInstance.get("/guideEntries/table", {params: args}),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/guideEntries/statistics"),
        list: (args?: any): Promise<AxiosResponse> =>
            axiosInstance.get("/guideEntries/list", {params: args}),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/guideEntries/single/${id}`),
        update: ({id, ...args}: { id: number; args: any }): Promise<AxiosResponse> =>
            axiosInstance.put(`/guideEntries/update/${id}`, {
                ...args,
            }),
        delete: (id: number): Promise<AxiosResponse> =>
            axiosInstance.delete(`/guideEntries/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> =>
            axiosInstance.post(`/guideEntries/restore/${id}`),
    },
};
