import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { Helpers } from '../helpers';
import { Ad_Placements } from './AdvertisementForm';
import AdvertismenetFilter from './AdvertismenetTableFilter';
import { ImagesHelpers } from '../helpers/images';




const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    width: '20%',
    key: "_id",
    render: (text: string) => {
      return (<Link to={`/advertisements/update/${text}`}>{text}</Link>)
    }
  },
  {
    title: 'Country',
    dataIndex: 'country',
    sorter: true,
    width: '20%',
    render: (text: any) => {
      return (text.name)
    }
  },
  {
    title: 'Placement',
    dataIndex: 'placement',
    key: "placement",
    sorter: true,
    width: '20%',
    render: (text: string) => {
      return (Ad_Placements.find(i => i.value === text)?.label)
    }
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: "image",
    width: '20%',
    render: (text: string) => {
      return (<img style={{ width: 100, height: 100, objectFit: "contain" }} alt="ad" src={ImagesHelpers.formatAmazonImage(text, { width: 300 })} />)
    }
  },
  {
    title: 'CreatedBy',
    dataIndex: 'createdBy',
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
    key: "createdBy",
    sorter: true,
    width: '20%',
  },
  {
    title: 'updatedBy',
    dataIndex: 'updatedBy',
    sorter: true,
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    width: '20%',
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
];

const AdvertisementTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Table
        tableId={"advertisementsTable"}
        deleteApiCall={Api.advertisements.delete}
        allowDelete={true}
        restoreApiCall={Api.advertisements.restore}
        actions={(record: any) => (
          <Link to={`/advertisements/update/${record._id}`}>View</Link>
        )}
        Filter={AdvertismenetFilter}
        apiCall={Api.advertisements.table} columns={columns} />
    </Space>
  )
};

export default AdvertisementTable;