import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { ImagesHelpers } from '../helpers/images';
import { Helpers } from '../helpers';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/articles/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: "Image",
        sorter: false,
        dataIndex: "image",
        width: '10%',
        key: "image",
        render: (text: string) => {
            return (<img style={{ width: 50, height: 50, objectFit: "cover" }} src={ImagesHelpers.formatAmazonImage(text, { width: 200 })} />)
        }
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: "title",
        sorter: true,
        width: '20%',
    },
    {
        title: 'CreatedBy',
        dataIndex: 'createdBy',
        render: (user: any) => {
            if (!user)
                return (null);
            return (<span>{user.firstName} {user.lastName}</span>)
        },
        key: "createdBy",
        sorter: true,
        width: '20%',
    },
    {
        title: 'updatedBy',
        dataIndex: 'updatedBy',
        sorter: true,
        render: (user: any) => {
            if (!user)
                return (null);
            return (<span>{user.firstName} {user.lastName}</span>)
        },
    },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        width: '20%',
        sorter: true,
        render: (date: Date) => {
            return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
        },
    },
];

const ArticleTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={"articlesTable"}
                deleteApiCall={Api.articles.delete}
                allowDelete={true}
                restoreApiCall={Api.articles.restore}
                actions={(record: any) => (
                    <Link to={`/articles/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.articles.table} columns={columns} />
        </Space>
    )
};

export default ArticleTable;