import { Button, Col, Input, message, Result, Row, Space, Checkbox, Switch, Select, Divider } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import { Contact } from "../types/contact";
import PhoneArrayField from "./PhoneArrayField";
import EmailArrayField from "./EmailsArrayField";
import { useCategoriesList } from "../hooks/useCategoriesList";
import { TagsInput } from "./TagsInput";
import { useContactsList } from "../hooks/useContactsList";
import { useCountriesList } from "../hooks/useCountriesList";

const ContactForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, watch } = useForm<Contact>();
    const [categories, categoriesLoading] = useCategoriesList();
    const [contacts, contactsLoading] = useContactsList();
    const [countries, countriesLoading] = useCountriesList();
    const { isCompany } = watch();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/contacts')
    }
    const imageFields = [
        {
            name: "image",
            singleValue: true,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.contacts.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.contacts.create,
            updateApiCall: Api.contacts.update,
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/contacts">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row gutter={24}>
                    <Col span={12}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <label htmlFor={"isCompany"}>Is Company</label>
                        <Controller
                            control={control}
                            as={<Switch style={{ display: 'block' }} />}
                            valueName="checked"
                            name="isCompany"
                        />
                        <FieldErrorMessage errors={errors} name="isCompany" />
                    </Col>
                </Row>
                {
                    !isCompany &&
                    <Row style={{ width: "100%" }} gutter={12}>
                        <Col span={6}>
                            <label htmlFor={"firstName"}>First Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"firstName"} />}
                                rules={{ required: true }}
                                name="firstName"
                            />
                            <FieldErrorMessage errors={errors} name="firstName" />
                        </Col>
                        <Col span={6}>
                            <label htmlFor={"lastName"}>Last Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"lastName"} />}
                                rules={{ required: true }}
                                name="lastName"
                            />
                            <FieldErrorMessage errors={errors} name="lastName" />
                        </Col>
                        <Col span={6}>
                            <label htmlFor={"jobTitle"}>Job Title</label>
                            <Controller
                                control={control}
                                as={<Input id={"jobTitle"} />}
                                name="jobTitle"
                            />
                            <FieldErrorMessage errors={errors} name="jobTitle" />
                        </Col>

                    </Row>

                }
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"company"}>Company</label>
                        <Controller
                            control={control}
                            as={<Input id={"company"} />}
                            rules={{ required: isCompany }}
                            name="company"
                        />
                        <FieldErrorMessage errors={errors} name="company" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"priority"}>Priority</label>
                        <Controller
                            control={control}
                            defaultValue={0}
                            as={<Select style={{ width: "100%" }} options={[{ label: "None", value: 0 }, { label: "Low", value: 1 }, { label: "Medium", value: 2 }, { label: "High", value: 3 },]} />}
                            name="priority"
                        />
                        <FieldErrorMessage errors={errors} name="priority" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <PhoneArrayField control={control} errors={errors} fieldName="phones" />
                    </Col>
                    <Col span={12}>
                        <EmailArrayField control={control} errors={errors} fieldName="emails" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"category"}>Category</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={categories.map(c => ({ label: c.name, value: c._id, key: c._id }))} />}
                            name="category"
                        />
                        <FieldErrorMessage errors={errors} name="category" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"tags"}>Tags</label>
                        <Controller
                            control={control}
                            as={<TagsInput />}
                            rules={{ required: false }}
                            name="tags"
                        />
                        <FieldErrorMessage errors={errors} name="tags" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"website"}>Website</label>
                        <Controller
                            control={control}
                            as={<Input id={"website"} />}
                            rules={{ required: false }}
                            name="website"
                        />
                        <FieldErrorMessage errors={errors} name="website" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"authorityPerson"}>Authority Person</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={contacts.map(i => ({ label: `${i.firstName} ${i.lastName}`, value: i._id, key: i._id }))} />}
                            rules={{ required: false }}
                            name="authorityPerson"
                        />
                        <FieldErrorMessage errors={errors} name="authorityPerson" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"country"}>Country</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={countries.map(i => ({ label: `${i.name}`, value: i._id, key: i._id }))} />}
                            rules={{ required: false }}
                            name="country"
                        />
                        <FieldErrorMessage errors={errors} name="country" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"coordinates.longitude"}>Longitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"coordinates.longitude"} />}
                            rules={{ required: false }}
                            name="coordinates.longitude"
                        />
                        <FieldErrorMessage errors={errors} name="coordinates.longitude" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"coordinates.latitude"}>Latitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"coordinates.latitude"} />}
                            rules={{ required: false }}
                            name="coordinates.latitude"
                        />
                        <FieldErrorMessage errors={errors} name="address" />
                    </Col>



                </Row>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"address"}>Address</label>
                        <Controller
                            control={control}
                            as={<Input.TextArea rows={2} id={"address"} />}
                            rules={{ required: false }}
                            name="address"
                        />
                        <FieldErrorMessage errors={errors} name="address" />
                    </Col>
                    <Col span={24}>
                        <label htmlFor={"notes"}>Notes</label>
                        <Controller
                            control={control}
                            as={<Input.TextArea rows={4} id={"notes"} />}
                            rules={{ required: false }}
                            name="notes"
                        />
                        <FieldErrorMessage errors={errors} name="notes" />
                    </Col>
                </Row>


            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default ContactForm;