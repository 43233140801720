import { useEffect, useState } from 'react';
import { Api } from '.';


export const useArticlesList: (query: any) => [Record<string, any>[], boolean] = (query) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Api.articles.list(query).then((res) => {
            setData(res.data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return [data, loading];
}