import { Select } from 'antd';
import React from 'react';
import { useFieldsList } from '../api/useFieldsList';
import { useCountriesList } from '../hooks/useCountriesList';


const CountrySelector: React.FC<any> = ({ value, onChange, multiple = false }) => {
    const [data, loading] = useCountriesList();
    return (
        <Select
            showSearch
            style={{ width: "100%" }}
            mode={multiple && "multiple"}
            optionFilterProp="label"
            options={data.map(d => ({ label: d.name, value: d._id }))}
            loading={loading}
            value={value}
            onChange={onChange} />
    )
};

export default CountrySelector;