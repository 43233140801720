import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { Contact } from '../types/contact';
import { Helpers } from '../helpers';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/contacts/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
        render: (text: string, document: Contact) => {
            return document.isCompany ? document.company : `${document.firstName} ${document.lastName}`;
        }
    },
    {
        title: 'CreatedBy',
        dataIndex: 'createdBy',
        render: (user: any) => {
            if (!user)
                return (null);
            return (<span>{user.firstName} {user.lastName}</span>)
        },
        key: "createdBy",
        sorter: true,
        width: '20%',
    },
    {
        title: 'updatedBy',
        dataIndex: 'updatedBy',
        sorter: true,
        render: (user: any) => {
            if (!user)
                return (null);
            return (<span>{user.firstName} {user.lastName}</span>)
        },
    },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        width: '20%',
        sorter: true,
        render: (date: Date) => {
            return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
        },
    },
];

const ContactTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={"contactsTable"}
                deleteApiCall={Api.contacts.delete}
                allowDelete={true}
                restoreApiCall={Api.contacts.restore}
                actions={(record: any) => (
                    <Link to={`/contacts/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.contacts.table} columns={columns} />
        </Space>
    )
};

export default ContactTable;