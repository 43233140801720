import { Select } from 'antd';
import React from 'react';
import { useGuideCategoriesList } from '../api/useGuideCategoriesList';
import { useInstitutionsList } from '../api/useInstitutionsList';


const InstitutionsSelector: React.FC<any> = ({ value, onChange, multiple = false }) => {
    const [data, loading] = useInstitutionsList();
    return (
        <Select
            showSearch
            style={{ width: "100%" }}
            mode={multiple && "multiple"}
            optionFilterProp="label"
            options={data.map(d => ({ label: d.name, value: d._id }))}
            loading={loading}
            value={value}
            onChange={onChange} />
    )
};

export default InstitutionsSelector;