import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ImagesHelpers } from '../helpers/images';
import _ from 'lodash';

export const useLoadFormData = ({ apiCall, setValue, id, imageFields, defaultValues = {} }: {
    id?: string,
    setValue: (args: any) => void,
    apiCall: (args: any) => Promise<AxiosResponse>,
    imageFields?: { name: string, singleValue: boolean }[],
    defaultValues?: any
}) => {

    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData]: [any, any] = useState(null);
    useEffect(() => {
        if (id) {
            setLoading(true);
            apiCall(parseInt(id)).then((res) => {
                setData(res.data);
                let clone = { ...res.data.data }

                if (imageFields) {
                    imageFields.forEach(field => {
                        _.set(clone, field.name, ImagesHelpers.formatForForm(_.get(clone, field.name), field.singleValue));
                    });

                }
                setValue({ ...defaultValues, ...clone });
            }).catch((err: AxiosError) => {
                if (err.response && err.response.status === 404) {
                    setNotFound(true);
                }
                else
                    console.error(err);
            }).finally(() => {
                setLoading(false);
            })
        }
        else
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiCall, id]);

    return [
        notFound,
        loading,
        data,
    ]

}