import { Button, Card, Col, Divider, Input, message, Result, Row, Space } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import { Country } from "../types/country";
import EventsManagementInput from './EventsManagementInput';
import ArticlesManagementInput from "./ArticlesManagementInput";
import SliderManagementInput from "./SliderManagementInput";
import TextEditorInput from "./TextEditorInput";

const CountryForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm<Country>();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/countries')
    }
    const imageFields = [
        // {
        //     name: "slider",
        //     singleValue: false,
        // },
        {
            name: "subscribeSection.bannerImage",
            singleValue: true,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.countries.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.countries.create,
            updateApiCall: Api.countries.update,
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/countries">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"name"}>Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"name"} />}
                            rules={{ required: true }}
                            name="name"
                        />
                        <FieldErrorMessage errors={errors} name="name" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"code"}>Code</label>
                        <Controller
                            control={control}
                            as={<Input placeholder="LB" id={"code"} />}
                            rules={{ required: true }}
                            name="code"
                        />
                        <FieldErrorMessage errors={errors} name="code" />
                    </Col>


                </Row>
                {
                    id &&
                    <Row>
                        <Col span={24}>
                            <Divider></Divider>
                            <label htmlFor={"code"}>Highlighted Events</label>
                            <Controller
                                control={control}
                                as={<EventsManagementInput query={{ country: id }} />}
                                rules={{ required: false }}
                                name="highlightedEvents"
                            />
                            <FieldErrorMessage errors={errors} name="highlightedEvents" />
                        </Col>
                        <Col span={24}>
                            <Divider></Divider>
                            <label htmlFor={"code"}>Highlighted Articles</label>
                            <Controller
                                control={control}
                                as={<ArticlesManagementInput query={{ country: id }} />}
                                rules={{ required: false }}
                                name="highlightedArticles"
                            />
                            <FieldErrorMessage errors={errors} name="highlightedArticles" />
                        </Col>
                        <Col span={24}>
                            <Divider></Divider>
                            <label htmlFor={"slider"}>Slider</label>
                            <Controller
                                control={control}
                                as={<SliderManagementInput />}
                                name="slider"
                            />
                            <FieldErrorMessage errors={errors} name="slider" />
                        </Col>
                        <Col span={24}>
                            <Card title="Subscribe Section">
                                <Row>
                                    <Col span={24}>
                                        <label htmlFor={"subscribeSection.bannerImage"}>Subscribe Banner Image</label>
                                        <Controller
                                            control={control}
                                            as={<FileUploader />}
                                            rules={{
                                                validate: ImagesHelpers.validate
                                            }}
                                            name="subscribeSection.bannerImage"
                                        />
                                        <FieldErrorMessage errors={errors} name="subscribeSection.bannerImage" />
                                    </Col>
                                </Row>
                                <Col span={24}>
                                    <label htmlFor={"subscribeSection.body"}>Title</label>
                                    <Controller
                                        control={control}
                                        as={<Input id={"subscribeSection.title"} />}
                                        rules={{ required: true }}
                                        name="subscribeSection.title"
                                    />
                                    <FieldErrorMessage errors={errors} name="subscribeSection.title" />
                                </Col>
                                <Col span={24}>
                                    <label htmlFor={"subscribeSection.body"}>Text</label>
                                    <Controller
                                        control={control}
                                        as={<TextEditorInput />}
                                        rules={{ required: false }}
                                        name="subscribeSection.body"
                                    />
                                    <FieldErrorMessage errors={errors} name="subscribeSection.body" />
                                </Col>
                               

                            </Card>
                        </Col>
                    </Row>
                }
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default CountryForm;