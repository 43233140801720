import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { DatePicker } from 'antd';
import { FieldErrorMessage } from './FieldErrorMessage';
import moment from 'moment';


type DatePickerField = {
    control: Control;
    fieldName: string;
    errors: FieldErrors<any>
}
const DatePickerField = ({ control, fieldName, errors }: DatePickerField) => (
    <>
        <label htmlFor={"publishDate"}>Publish Date</label>
        <Controller
            defaultValue={() => new Date()}

            control={control}
            as={({ onChange, onBlur, value }) => (<DatePicker format="DD-MM-YYYY"
                style={{ display: 'block' }} onChange={v => onChange(v?.toDate())} value={value && moment(value)} />)}
            name={fieldName}
        />
        <FieldErrorMessage errors={errors} name={fieldName} />
    </>
)

export default DatePickerField;