import {
    DashboardOutlined,
    FormOutlined,
    PlusOutlined,
    TagsOutlined,
    TeamOutlined,
    GlobalOutlined,
    BuildOutlined,
    CalendarOutlined,
    PaperClipOutlined,
    MailOutlined,
    DollarOutlined,
    GroupOutlined
} from "@ant-design/icons/lib";
import {Button} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import {Api} from "./api";
import ContactFormForm from "./components/ContactFormForm";
import ContactFormTable from "./components/ContactFormTable";
import PageForm from "./components/PageForm";
import PageTable from "./components/PageTable";
import TagForm from "./components/TagForm";
import TagTable from "./components/TagTable";
import ContactTable from "./components/ContactTable";
import ContactForm from "./components/ContactForm";
import CountryTable from "./components/CountryTable";
import CountryForm from "./components/CountryForm";
import VenueForm from "./components/VenueForm";
import VenueTable from "./components/VenueTable";
import EventTable from "./components/EventTable";
import EventForm from "./components/EventForm";
import ArticleTable from "./components/ArticleTable";
import ArticleForm from "./components/ArticleForm";
import EmailSubscriptionTable from "./components/EmailSubscriptionTable";
import EmailSubscriptionForm from "./components/EmailSubscriptionForm";
import AdvertisementForm from "./components/AdvertisementForm";
import AdvertisementTable from "./components/AdvertisementTable";
import EventSubmissionTable from "./components/EventSubmissionTable";
import EventSubmissionSingle from "./components/EventSubmissionView";
import WebsiteUserTable from "./components/WebsiteUserTable";
import GuideCategoryTable from "./components/GuideCategoryTable";
import GuideCategoryForm from "./components/GuideCategoryForm";
import ProfessionTable from "./components/ProfessionTable";
import ProfessionForm from "./components/ProfessionForm";
import InstitutionTable from "./components/InstitutionTable";
import InstitutionForm from "./components/InstitutionForm";
import FieldTable from "./components/FieldTable";
import FieldForm from "./components/FieldForm";
import GuideEntryTable from "./components/GuideEntryTable";
import GuideEntryForm from "./components/GuideEntryForm";
import DonationTable from "./components/DonationTable";
import DonationForm from "./components/DonationForm";

const CategoriesForm = React.lazy(() => import("./components/CategoriesCreate"));
const CategoriesTable = React.lazy(() => import("./components/CategoriesList"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const UsersForm = React.lazy(() => import("./components/UsersCreate"));
const UsersList = React.lazy(() => import("./components/UsersList"));

export const Routes: any = [
    {
        title: "Dashboard",
        component: Dashboard,
        icon: DashboardOutlined,
        hideFromMenu: false,
        breadcrumb: "I love sandwiches",
        path: "/",
        isHomePage: true,
    },
    {
        title: "Users",
        icon: TeamOutlined,
        path: "/users",
        buttons: [
            <Link key="create" to="/users/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add User
                </Button>
            </Link>,
        ],
        component: UsersList,
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/users/create",
                component: UsersForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/users/update/:id",
                component: UsersForm,
            },
        ],
    },
    {
        title: "Website Users",
        icon: TeamOutlined,
        path: "/websiteusers",
        component: WebsiteUserTable,
        routes: [
            // {
            //   title: "Create",
            //   icon: PlusOutlined,
            //   path: "/users/create",
            //   component: UsersForm,
            // },
            // {
            //   title: "Update",
            //   hideFromMenu: true,
            //   path: "/users/update/:id",
            //   component: UsersForm,
            // },
        ],
    },
    {
        title: "Contacts",
        icon: TeamOutlined,
        path: "/contacts",
        dataRoute: Api.tags.statistics,
        component: ContactTable,
        buttons: [
            <Link key="create" to="/contacts/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Contact
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/contacts/create",
                component: ContactForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/contacts/update/:id",
                component: ContactForm,
            },
        ],
    },
    {
        title: "Tags",
        icon: TagsOutlined,
        path: "/tags",
        dataRoute: Api.tags.statistics,
        component: TagTable,
        buttons: [
            <Link key="create" to="/tags/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Tag
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/tags/create",
                component: TagForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/tags/update/:id",
                component: TagForm,
            },
        ],
    },
    {
        title: "Countries",
        icon: GlobalOutlined,
        path: "/countries",
        dataRoute: Api.countries.statistics,
        component: CountryTable,
        buttons: [
            <Link key="create" to="/countries/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Country
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/countries/create",
                component: CountryForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/countries/update/:id",
                component: CountryForm,
            },
        ],
    },
    {
        title: "Venues",
        icon: BuildOutlined,
        path: "/venues",
        dataRoute: Api.venues.statistics,
        component: VenueTable,
        buttons: [
            <Link key="create" to="/venues/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Venue
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/venues/create",
                component: VenueForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/venues/update/:id",
                component: VenueForm,
            },
        ],
    },
    {
        title: "Categories",
        icon: TagsOutlined,
        path: "/categories",
        dataRoute: Api.categories.statistics,
        component: CategoriesTable,
        buttons: [
            <Link key="create" to="/categories/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Category
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/categories/create",
                component: CategoriesForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/categories/update/:id",
                component: CategoriesForm,
            },
        ],
    },
    {
        title: "Events",
        icon: CalendarOutlined,
        path: "/events",
        dataRoute: Api.events.statistics,
        component: EventTable,
        buttons: [
            <Link key="create" to="/events/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Event
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/events/create",
                component: EventForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/events/update/:id",
                component: EventForm,
            },
        ],
    },
    {
        title: "Articles",
        icon: PaperClipOutlined,
        path: "/articles",
        dataRoute: Api.articles.statistics,
        component: ArticleTable,
        buttons: [
            <Link key="create" to="/articles/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Article
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/articles/create",
                component: ArticleForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/articles/update/:id",
                component: ArticleForm,
            },
        ],
    },
    {
        title: "Pages",
        icon: TagsOutlined,
        buttons: [],
        path: "/pages",
        dataRoute: Api.pages.statistics,
        component: PageTable,
        routes: [
            {
                title: "Page Management",
                hidePageHeader: true,
                hideFromMenu: true,
                path: "/pages/update/:id",
                component: PageForm,
            },
        ],
    },

    {
        title: "Email Subscriptions",
        icon: MailOutlined,
        path: "/email-subscriptions",
        dataRoute: Api.emailSubscriptions.statistics,
        component: EmailSubscriptionTable,
        routes: [
            {
                title: "Update",
                hideFromMenu: true,
                path: "/email-subscriptions/update/:id",
                component: EmailSubscriptionForm,
            },
        ],
    },
    {
        title: "Contact Forms",
        icon: FormOutlined,
        path: "/contactForms",
        dataRoute: Api.contactForms.statistics,
        component: ContactFormTable,
        routes: [
            {
                title: "Update",
                hideFromMenu: true,
                path: "/contactForms/update/:id",
                component: ContactFormForm,
            },
        ],
    },

    {
        title: "Advertisements",
        icon: DollarOutlined,
        path: "/advertisements",
        dataRoute: Api.advertisements.statistics,
        component: AdvertisementTable,
        buttons: [
            <Link key="create" to="/advertisements/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Advertisement
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/advertisements/create",
                component: AdvertisementForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/advertisements/update/:id",
                component: AdvertisementForm,
            },
        ],
    },

    {
        title: "EventSubmissions",
        icon: GroupOutlined,
        path: "/event-submissions",
        dataRoute: Api.advertisements.statistics,
        component: EventSubmissionTable,
        routes: [
            {
                title: "View",
                hideFromMenu: true,
                path: "/event-submissions/single/:id",
                component: EventSubmissionSingle,
            },
        ],
    },
    {
        title: "Guide Categories",
        icon: GroupOutlined,
        path: "/guideCategories",
        dataRoute: Api.guideCategories.statistics,
        component: GuideCategoryTable,
        buttons: [
            <Link key="create" to="/guideCategories/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Category
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/guideCategories/create",
                component: GuideCategoryForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/guideCategories/update/:id",
                component: GuideCategoryForm,
            },
        ],
    },
    {
        title: "Professions",
        icon: GroupOutlined,
        path: "/professions",
        dataRoute: Api.professions.statistics,
        component: ProfessionTable,
        buttons: [
            <Link key="create" to="/professions/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Profession
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/professions/create",
                component: ProfessionForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/professions/update/:id",
                component: ProfessionForm,
            },
        ],
    },
    {
        title: "Institutions",
        icon: GroupOutlined,
        path: "/institutions",
        dataRoute: Api.institutions.statistics,
        component: InstitutionTable,
        buttons: [
            <Link key="create" to="/institutions/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Institution
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/institutions/create",
                component: InstitutionForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/institutions/update/:id",
                component: InstitutionForm,
            },
        ],
    },
    {
        title: "Work Fields",
        icon: GroupOutlined,
        path: "/fields",
        dataRoute: Api.fields.statistics,
        component: FieldTable,
        buttons: [
            <Link key="create" to="/fields/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Field
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/fields/create",
                component: FieldForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/fields/update/:id",
                component: FieldForm,
            },
        ],
    },
    {
        title: "Work Fields",
        icon: GroupOutlined,
        path: "/fields",
        dataRoute: Api.fields.statistics,
        component: FieldTable,
        buttons: [
            <Link key="create" to="/fields/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Field
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/fields/create",
                component: FieldForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/fields/update/:id",
                component: FieldForm,
            },
        ],
    },
    {
        title: "Guide Entry",
        icon: GroupOutlined,
        path: "/guideEntries",
        dataRoute: Api.guideEntries.statistics,
        component: GuideEntryTable,
        buttons: [
            <Link key="create" to="/guideEntries/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Guide
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/guideEntries/create",
                component: GuideEntryForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/guideEntries/update/:id",
                component: GuideEntryForm,
            },
        ],
    },

    {
        title: "Guide Entry",
        icon: GroupOutlined,
        path: "/guideEntries",
        dataRoute: Api.guideEntries.statistics,
        component: GuideEntryTable,
        buttons: [
            <Link key="create" to="/guideEntries/create">
                <Button type="primary" icon={<PlusOutlined/>}>
                    Add Guide
                </Button>
            </Link>,
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/guideEntries/create",
                component: GuideEntryForm,

            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/guideEntries/update/:id",
                component: GuideEntryForm,
            },
        ],
    },

    {
        title: "Donations",
        icon: GroupOutlined,
        path: "/donations",
        dataRoute: Api.donations.statistics,
        component: DonationTable,
        routes: [
            {
                title: "Update",
                hideFromMenu: true,
                path: "/donations/update/:id",
                component: DonationForm,
            },
        ],
    },

];
