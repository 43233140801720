import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useTrackedState } from "reactive-react-redux";
import { Api } from "../api";
import { ImagesHelpers } from "../helpers/images";
import { State } from "../store";

const DataExportButton: React.FC<any> = ({ apiCall, tableId }) => {
  const state = useTrackedState<State>();
  const filterValue = state.tablesData[tableId] ? state.tablesData[tableId].filter || {} : {};
  const [loading, setLoading] = useState(false);

  console.log(filterValue);
  const onExport = () => {
    setLoading(true);
    apiCall({ filter: filterValue }).then((res: any) => {
      console.log(res.data);
      let a = document.createElement("a");
    //   document.body.appendChild(a);
      a.target = "_blank";
      a.href = ImagesHelpers.formatUrlOnly(res.data);
      a.download = "export.csv";
      a.click();
    //   document.body.removeChild(a);
      setLoading(false);
    });
  };
  return (
    <Button icon={<UploadOutlined />} size="small" loading={loading} onClick={onExport}>
      Export
    </Button>
  );
};

export default DataExportButton;
