import { useEffect, useState } from 'react';
import { Api } from '.';


export const useFieldsList: () => [Record<string, any>[], boolean] = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Api.fields.list().then((res) => {
            setData(res.data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return [data, loading];
}