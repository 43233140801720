import React, { useState, useEffect } from 'react';
import { Modal, Space, Row, Col, Input, Button, Checkbox, Switch } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useLoadFormData } from '../hooks/useLoadFormData';
import { useSubmitForm } from '../helpers/forms';
import { FieldErrorMessage } from './FieldErrorMessage';
import { FileUploader } from './FileUploader';
import { ImagesHelpers } from '../helpers/images';
import TextEditorInput from './TextEditorInput';

const SliderForm = ({ data, onChange, value }: any) => {
    const { handleSubmit, errors, control, reset } = useForm({});
    const onSuccess = () => {
        console.log("done");
    }
    const onSubmit = (data: any) => {
        let clone = { ...data };
        console.log(clone.image);
        clone.image = ImagesHelpers.formatForSubmit(clone.image, true);
        console.log(clone.image);
        onChange({ ...value, ...clone });
    }

    useEffect(() => {
        if (value) {
            let clone = { ...value };
            if (clone.image)
                clone.image = ImagesHelpers.formatForForm(clone.image, true);
            reset(clone);
        }

    }, [value]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={6}>
                    <Col span={12}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>

                </Row>
                <Row gutter={6}>
                    <Col span={6}>
                        <label htmlFor={"onClick.link"}>Click Link</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            rules={{
                                required: false
                            }}
                            name="onClick.link"
                        />
                        <FieldErrorMessage errors={errors} name="onClick.link" />
                    </Col>
                    <Col span={4}>
                        <label htmlFor={"onClick.newTab"}>New tab</label>
                        <div>
                            <Controller
                                control={control}
                                as={<Switch />}
                                rules={{
                                    required: false
                                }}
                                valueName="checked"
                                name="onClick.newTab"
                            />
                        </div>
                        <FieldErrorMessage errors={errors} name="onClick.newTab" />
                    </Col>
                    <Col span={4}>
                        <label htmlFor={"onClick.internalLink"}>Internal Link</label>
                        <div>
                            <Controller
                                control={control}
                                as={<Switch />}
                                rules={{
                                    required: false
                                }}
                                valueName="checked"
                                name="onClick.internalLink"
                            />
                        </div>
                        <FieldErrorMessage errors={errors} name="onClick.internalLink" />
                    </Col>
                    <Col span={24}>
                        <label htmlFor={"text"}>Slider Text</label>
                        <div>
                            <Controller
                                control={control}
                                as={TextEditorInput}
                                rules={{ required: false }}
                                name="text"
                            />
                        </div>
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>

                    <Col span={6}>
                        <label htmlFor={"button.text"}>Button Text</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            rules={{
                                required: false
                            }}
                            name="button.text"
                        />
                        <FieldErrorMessage errors={errors} name="button.text" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"button.link"}>Button Link</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            rules={{
                                required: false,

                            }}
                            name="button.link"
                        />
                        <FieldErrorMessage errors={errors} name="button.link" />
                    </Col>
                    <Col span={4}>
                        <label htmlFor={"button.onClick.newTab"}>New tab</label>
                        <div>
                            <Controller
                                control={control}
                                as={<Switch />}
                                rules={{
                                    required: false
                                }}
                                valueName="checked"
                                name="button.onClick.newTab"
                            />
                        </div>
                        <FieldErrorMessage errors={errors} name="button.onClick.newTab" />
                    </Col>
                    <Col span={4}>
                        <label htmlFor={"button.onClick.internalLink"}>Internal Link</label>
                        <div>
                            <Controller
                                control={control}
                                as={<Switch />}
                                rules={{
                                    required: false
                                }}
                                valueName="checked"
                                name="button.onClick.internalLink"
                            />
                        </div>
                        <FieldErrorMessage errors={errors} name="button.onClick.internalLink" />
                    </Col>


                </Row>

            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
        </form>);
};
const SliderFormModal = ({ button, data, onChange, value }: any) => {
    const [visible, setVisible] = useState(false);
    const onClick = () => {
        setVisible(!visible);
    }
    const handleChange = (value: any) => {
        onChange(value);
        setVisible(false);
    }
    return (
        <div >
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                {button(onClick)}
            </div>
            <Modal
                width="50%"
                footer={null}
                onCancel={onClick}
                destroyOnClose={true}
                title="SLIDE MANAGEMENT"
                visible={visible}
            // onOk={this.handleOk}
            // confirmLoading={confirmLoading}
            // onCancel={this.handleCancel}
            >
                <SliderForm value={value} data={data} onChange={handleChange} />
            </Modal>
        </div>
    )
};

export default SliderFormModal;