import { AxiosResponse } from "axios";
import { useState } from "react";
import { ImagesHelpers } from "./images";
import _ from 'lodash';
export const useSubmitForm = ({ imageFields, updateId, createApiCall, updateApiCall, onSuccess, defaultValues = {} }: {
    createApiCall: (args: any) =>
        Promise<AxiosResponse>,
    updateApiCall: (args: any) =>
        Promise<AxiosResponse>, imageFields?: { name: string, singleValue: boolean }[], updateId?: string, onSuccess: Function, defaultValues?: any
}
): [boolean, (args: Record<string, any>) => void] => {
    const [loading, setLoading]: [boolean, Function] = useState(false);


    // data.image=data.image[0].response.

    //default values is used to force setting non existing objects like case with useArrayForm() unctrolled components
    const submit = (data: Record<string, any>) => {
        console.log("submited", data);
        setLoading(true);
        if (imageFields) {
            imageFields.forEach(field => {
                _.set(data, field.name, ImagesHelpers.formatForSubmit(_.get(data, field.name), field.singleValue));
            });
        }
        if (!updateId) {
            createApiCall({ ...defaultValues, ...data }).then(res => {
                onSuccess();
            }).finally(() => setLoading(false)).catch((err) => {
                console.error(err);
            });

        }
        else {
            updateApiCall({ id: parseInt(updateId), ...defaultValues, ...data }).then(res => {
                onSuccess();
            }).finally(() => setLoading(false)).catch((err) => {
                console.error(err);
            });
        }
    };
    return [loading, submit];
}