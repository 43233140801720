import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { Ad_Placements } from "./AdvertisementForm";
import { useCountriesList } from "../hooks/useCountriesList";
import { useVenuesList } from "../api/useVenuesList";
import DataExportButton from "./DataExportButton";
import { Api } from "../api";

export type AdvertismenetTableFilterProp = {
  value: any;
  onChange: (value: any) => void;
  filterDefinitions?: any;
  deletedFilter?: boolean;
};
const default_definitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "title",
    type: "search",
    label: "Title",
  },
  {
    key: "countries",
    type: "array",
    label: "Countries",
  },
  {
    key: "venue",
    type: "array",
    label: "Venues",
  },
  {
    key: "meta.submittedByUser",
    type: "boolean",
    label: "User Submitted",
  },
  {
    key: "showDeleted",
    type: "showDeleted",
    excludeFromGenerator: true,
  },
];
const EventsTableFilter = ({
  value,
  onChange,
  filterDefinitions,
  deletedFilter = true,
}: AdvertismenetTableFilterProp) => {
  const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
  const [definitions, setDefinitions]: any = useState(default_definitions);
  const [countries, loadingCountries] = useCountriesList();
  const [venues, loadingVenues] = useVenuesList();
  useEffect(() => {
    if (deletedFilter)
      setDefinitions([
        ...definitions,
        {
          key: "showDeleted",
          type: "showDeleted",
          excludeFromGenerator: true,
        },
      ]);
  }, [deletedFilter]);
  const clearFilter = () => {
    reset({ showDeleted: false, meta: { submittedByUser: false } });
    onChange({
      filter: {},
      definitions,
    });
  };

  const handleChange = useCallback(
    (v: any) => {
      console.log(v, definitions);
      onChange({
        filter: v,
        definitions,
      });
    },
    [onChange]
  );
  // const call = useCallback(() => {
  //     reset(value);
  // }, [value, reset]);
  // useEffect(() => {
  //     call();
  // }, [value, call])
  const watchAllFields = watch("showDeleted");
  const watchUserSubmitted = watch("meta.submittedByUser");

  useEffect(() => {
    if (watchAllFields !== undefined) {
      handleSubmit(handleChange)();
    }
  }, [watchAllFields, watchUserSubmitted]);
  return (
    <div className="white-container" style={{ marginBottom: 12 }}>
      <form onSubmit={handleSubmit(handleChange)}>
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={4}>
              <div className="custom-form-item">
                <label className="mr-10" htmlFor={"_id"}>
                  ID:{" "}
                </label>
                <Input
                  onChange={(v) => {
                    onChange({ filter: { ...value, _id: v.target.value }, definitions });
                  }}
                  id={"_id"}
                />
              </div>
            </Col>
            <Col span={4}>
              <div className="custom-form-item">
                <label className="mr-10" htmlFor={"_id"}>
                  Title:{" "}
                </label>
                <Input
                  id={"title"}
                  onChange={(v) => {
                    onChange({ filter: { ...value, title: v.target.value }, definitions });
                  }}
                />
              </div>
            </Col>
            <Col span={4}>
              <div className="custom-form-item">
                <label className="mr-10" htmlFor={"meta.submittedByUser"}>
                  Country
                </label>
                <Select
                  mode="multiple"
                  onChange={(v) => {
                    onChange({ filter: { ...value, countries: v }, definitions });
                  }}
                  optionFilterProp="label"
                  style={{ width: "100%" }}
                  options={countries.map((c) => ({ label: c.name, value: c._id }))}
                />
              </div>
            </Col>
            <Col span={4}>
              <div className="custom-form-item">
                <label className="mr-10" htmlFor={"meta.submittedByUser"}>
                  Venue
                </label>
                <Select
                  mode="multiple"
                  onChange={(v) => {
                    onChange({ filter: { ...value, venue: v }, definitions });
                  }}
                  optionFilterProp="label"
                  style={{ width: "100%" }}
                  options={venues.map((c) => ({ label: c.name, value: c._id }))}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="custom-form-item">
                <label className="mr-10" htmlFor={"meta.submittedByUser"}>
                  User Submitted
                </label>
                <Controller
                  onChange={([e]: any) => e.target.checked}
                  control={control}
                  valueName="checked"
                  as={Checkbox}
                  name="meta.submittedByUser"
                />
              </div>
              <FieldErrorMessage
                style={{ textAlign: "center" }}
                errors={errors}
                name="meta.submittedByUser"
              />
            </Col>
          </Row>
          <Row justify="end">
            <Space size="small">
              <div className="">
                <label className="mr-10" htmlFor={"showDeleted"}>
                  Show Deleted
                </label>
                <Controller
                  onChange={([e]: any) => e.target.checked}
                  control={control}
                  valueName="checked"
                  as={Checkbox}
                  name="showDeleted"
                />
              </div>
              <Button htmlType="submit" size="small" type="dashed">
                Submit
              </Button>
              <Button size="small" onClick={clearFilter} danger>
                Reset
              </Button>
              <DataExportButton tableId="eventsTable" apiCall={Api.events.export} />
            </Space>
          </Row>
        </Space>
      </form>
    </div>
  );
};

export default EventsTableFilter;
