import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";
import { Helpers } from "../helpers";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/contactForms/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: true,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
  },
  {
    title: 'CreatedBy',
    dataIndex: 'createdBy',
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
    key: "createdBy",
    sorter: true,
    width: '20%',
  },
  {
    title: 'updatedBy',
    dataIndex: 'updatedBy',
    sorter: true,
    render: (user: any) => {
      if (!user)
        return (null);
      return (<span>{user.firstName} {user.lastName}</span>)
    },
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    width: '20%',
    sorter: true,
    render: (date: Date) => {
        return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
},
];
const filterDefinitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "firstName",
    type: "search",
    label: "First Name",
    span: 4,
  },
  {
    key: "lastName",
    type: "search",
    label: "Last Name",
    span: 4,
  },
  {
    key: "email",
    type: "search",
    label: "Email",
    span: 4,
  },
  {
    key: "phone",
    type: "search",
    label: "Phone",
    span: 4,
  },
];

const ContactFormTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        filterDefinitions={filterDefinitions}
        tableId={"contactFormsTable"}
        deleteApiCall={Api.contactForms.delete}
        allowDelete={true}
        restoreApiCall={Api.contactForms.restore}
        actions={(record: any) => <Link to={`/contactForms/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.contactForms.table}
        columns={columns}
      />
    </Space>
  );
};

export default ContactFormTable;
