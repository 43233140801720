import { Button, Col, Input, message, Result, Row, Select, Space, Typography } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import CountrySelector from "./CountrySelector";
import EmailArrayField from "./EmailsArrayField";
import { FieldErrorMessage } from "./FieldErrorMessage";
import FieldsSelector from "./FieldsSelector";
import { FileUploader } from "./FileUploader";
import GuideCategoriesSelector from "./GuideCategoriesSelector";
import InstitutionsSelector from "./InstitutionsSelector";
import PhoneArrayFieldSimple from "./PhoneArrayFieldSimple";
import ProfessionsSelector from "./ProfessionsSelector";
import { TagsInput } from "./TagsInput";
import TextEditorInput from "./TextEditorInput";


const GuideEntryForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, watch } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/guideEntries')
    }
    const type = watch("type");
    const imageFields = [
        {
            name: "thumbnailImage",
            singleValue: true,
        }, {
            name: "imagesGallery",
            singleValue: false,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.guideEntries.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.guideEntries.create,
            updateApiCall: Api.guideEntries.update,
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/guideEntries">Back to list</Link>
            </Button>}
        />)

    console.log(type);
    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"type"}>Type</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={[{ label: "Individual", value: "individual" }, { label: "Entity", value: "entity" }]} />}
                            rules={{ required: true }}
                            name="type"
                        />
                        <FieldErrorMessage errors={errors} name="type" />
                    </Col>

                </Row>
                <Row gutter={8}>
                    {
                        type === "entity" &&
                        <Col span={6}>
                            <label htmlFor={"name"}>Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"name"} />}
                                rules={{ required: true }}
                                name="name"
                            />
                            <FieldErrorMessage errors={errors} name="name" />
                        </Col>

                    }
                    {
                        type === "individual" &&
                        <Col span={6}>
                            <label htmlFor={"firstName"}>First Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"firstName"} />}
                                rules={{ required: true }}
                                name="firstName"
                            />
                            <FieldErrorMessage errors={errors} name="firstName" />
                        </Col>

                    }
                    {
                        type === "individual" &&
                        <Col span={6}>
                            <label htmlFor={"lastName"}>Last Name</label>
                            <Controller
                                control={control}
                                as={<Input id={"lastName"} />}
                                rules={{ required: true }}
                                name="lastName"
                            />
                            <FieldErrorMessage errors={errors} name="lastName" />
                        </Col>

                    }
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <label htmlFor={"categories"}>Categories</label>
                        <Controller
                            control={control}
                            as={<GuideCategoriesSelector multiple />}
                            rules={{ required: true }}
                            name="categories"
                        />
                        <FieldErrorMessage errors={errors} name="categories" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"professions"}>Professions</label>
                        <Controller
                            control={control}
                            as={<ProfessionsSelector multiple />}
                            name="professions"
                        />
                        <FieldErrorMessage errors={errors} name="professions" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"fields"}>Work Fields</label>
                        <Controller
                            control={control}
                            as={<FieldsSelector multiple />}
                            name="fields"
                        />
                        <FieldErrorMessage errors={errors} name="professions" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"institutions"}>Institutions</label>
                        <Controller
                            control={control}
                            as={<InstitutionsSelector multiple />}
                            name="institutions"
                        />
                        <FieldErrorMessage errors={errors} name="institutions" />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"thumbnailImage"}>Thumbnail Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="thumbnailImage"
                        />
                        <FieldErrorMessage errors={errors} name="thumbnailImage" />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"imagesGallery"}>Images gallery</label>
                        <Controller
                            control={control}
                            as={<FileUploader limit={10} />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="imagesGallery"
                        />
                        <FieldErrorMessage errors={errors} name="imagesGallery" />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <label htmlFor={"text"}>Text</label>
                        <Controller
                            control={control}
                            as={<TextEditorInput />}
                            rules={{ required: false }}
                            name="text"
                        />
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <label htmlFor={"tags"}>Tags</label>
                        <Controller
                            control={control}
                            as={<TagsInput id="tags" />}
                            name="tags"
                        />
                        <FieldErrorMessage errors={errors} name="tags" />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <label htmlFor={"contactName"}>Contact Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"contactName"} />}
                            name="contactName"
                        />
                        <FieldErrorMessage errors={errors} name="contactName" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <PhoneArrayFieldSimple control={control} errors={errors} fieldName="phoneNumbers" />
                    </Col>
                    <Col span={12}>
                        <EmailArrayField control={control} errors={errors} fieldName="emails" />
                    </Col>

                </Row>
                <Row>
                    <Col span={6}>
                        <label htmlFor={"country"}>Country</label>
                        <Controller
                            control={control}
                            as={<CountrySelector />}
                            name="country"
                        />
                        <FieldErrorMessage errors={errors} name="country" />
                    </Col>

                </Row>
                <Typography.Title level={4} >Geolocation</Typography.Title>
                <Row gutter={8}>

                    <Col span={6}>
                        <label htmlFor={"geolocation.latitude"}>Latitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"geolocation.latitude"} />}
                            name="geolocation.latitude"
                        />
                        <FieldErrorMessage errors={errors} name="geolocation.latitude" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"geolocation.longitude"}>Longitude</label>
                        <Controller
                            control={control}
                            as={<Input id={"geolocation.longitude"} />}
                            name="geolocation.longitude"
                        />
                        <FieldErrorMessage errors={errors} name="geolocation.longitude" />
                    </Col>

                </Row>
                <Typography.Title level={4} >Social Media</Typography.Title>
                <Row gutter={8}>
                    <Col span={6}>
                        <label htmlFor={"website"}>Website</label>
                        <Controller
                            control={control}
                            as={<Input id={"website"} />}
                            name="website"
                        />
                        <FieldErrorMessage errors={errors} name="website" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.facebook"}>Facebook</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.facebook"} />}
                            name="socialMedia.facebook"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.facebook" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.instagram"}>Instagram</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.instagram"} />}
                            name="socialMedia.instagram"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.instagram" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.twitter"}>Twitter</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.twitter"} />}
                            name="socialMedia.twitter"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.twitter" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.linkedIn"}>LinkedIn</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.linkedIn"} />}
                            name="socialMedia.linkedIn"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.linkedIn" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.pinterest"}>Pinterest</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.pinterest"} />}
                            name="socialMedia.pinterest"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.pinterest" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMedia.youtube"}>Youtube</label>
                        <Controller
                            control={control}
                            as={<Input id={"socialMedia.youtube"} />}
                            name="socialMedia.youtube"
                        />
                        <FieldErrorMessage errors={errors} name="socialMedia.youtube" />
                    </Col>

                </Row>


            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default GuideEntryForm;