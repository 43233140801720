import { Button, Col, Input, message, Result, Row, Space, Select, Switch } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import { Article } from "../types/article";
import { Contact } from "../types/contact";
import { useContactsList } from "../hooks/useContactsList";
import DatePickerField from "./DatePickerField";
import { TagsInput } from "./TagsInput";
import { useCategoriesList } from "../hooks/useCategoriesList";
import { useCountriesList } from "../hooks/useCountriesList";
import TextEditorInput from './TextEditorInput';

const ArticleForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, watch } = useForm<Article>();
    const [contacts, contactsLoading] = useContactsList();
    const [categories, categoriesLoading] = useCategoriesList();
    const [countries, countriesLoading] = useCountriesList();
    const showInAllCountries = watch("showInAllCountries");
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/articles')
    }
    const imageFields = [
        {
            name: "image",
            singleValue: true,
        },
        {
            name: "imageGallery",
            singleValue: false,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.articles.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.articles.create,
            updateApiCall: Api.articles.update,
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/articles">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"title"}>Title</label>
                        <Controller
                            control={control}
                            as={<Input id={"title"} />}
                            rules={{ required: true }}
                            name="title"
                        />
                        <FieldErrorMessage errors={errors} name="title" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"author"}>Author</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} options={contacts.map((c) => ({ value: c._id, key: c._id, label: c.isCompany ? c.company : `${c.firstName} ${c.lastName}` }))} />}
                            rules={{ required: false }}
                            name="author"
                        />
                        <FieldErrorMessage errors={errors} name="author" />
                    </Col>
                    <Col span={6}>
                        <DatePickerField control={control} fieldName="publishDate" errors={errors} />
                    </Col>


                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={4}>
                        <label htmlFor={"showInAllCountries"}>Show in all countries </label>
                        <Controller
                            control={control}
                            as={<Switch style={{ display: 'block' }} />}
                            defaultValue={false}
                            valueName="checked"
                            name="showInAllCountries"
                        />
                        <FieldErrorMessage errors={errors} name="showInAllCountries" />
                    </Col>
                    {
                        !showInAllCountries && <Col span={6}>
                            <label htmlFor={"countries"}>Countries</label>
                            <Controller
                                control={control}
                                as={<Select mode="multiple" style={{ width: "100%" }} id={"countries"} options={countries.map(c => ({ label: c.name, value: c._id, key: c._id }))} />}
                                rules={{ required: true }}
                                name="countries"
                            />
                            <FieldErrorMessage errors={errors} name="countries" />
                        </Col>
                    }
                    <Col span={6}>
                        <label htmlFor={"category"}>Category</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} id={"category"} options={categories.map(c => ({ label: c.name, value: c._id, key: c._id }))} />}
                            rules={{ required: true }}
                            name="category"
                        />
                        <FieldErrorMessage errors={errors} name="category" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"tags"}>Tags</label>
                        <Controller
                            control={control}
                            as={<TagsInput id="tags" />}
                            name="tags"
                        />
                        <FieldErrorMessage errors={errors} name="tags" />
                    </Col>

                </Row>
                <Row gutter={6}>
                    <Col span={6}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"imageGallery"}>Image Gallery</label>
                        <Controller
                            control={control}
                            as={<FileUploader limit={10} />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="imageGallery"
                        />
                        <FieldErrorMessage errors={errors} name="imageGallery" />
                    </Col>
                </Row>

                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"text"}>Text</label>
                        <Controller
                            control={control}
                            as={<TextEditorInput id={"text"} />}
                            rules={{ required: true }}
                            name="text"
                        />
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>


                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default ArticleForm;