import { Button, Col, Input, message, Result, Row, Space, Switch, Select, DatePicker, InputNumber, Card } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import { Event } from "../types/event";
import { useCategoriesList } from "../hooks/useCategoriesList";
import { TagsInput } from "./TagsInput";
import DateArrayField from "./DateArrayField";
import moment from 'moment';
import DatePickerField from "./DatePickerField";
import { TwitterOutlined, YoutubeOutlined, FacebookOutlined, InstagramOutlined, GlobalOutlined } from "@ant-design/icons";
import { useCountriesList } from "../hooks/useCountriesList";
import TextEditorInput from "./TextEditorInput";
import { useVenuesList } from "../api/useVenuesList";
import ToRememberFormInput from "./ToRememberFormInput";

const EventForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, watch, register, setValue, clearError, setError } = useForm<Event>({ defaultValues: { toRemember: [] } });
    const isFree = watch("isFree");
    const showInAllCountries = watch("showInAllCountries");
    const isVirtual = watch("isVirtual");
    const [categories, categoriesLoading] = useCategoriesList();
    const [countries, countriesLoading] = useCountriesList();
    const [venues, venuesLoading] = useVenuesList();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/events')
    }
    const imageFields = [
        {
            name: "image",
            singleValue: true,
        },
        {
            name: "imageGallery",
            singleValue: false,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.events.single, id, setValue: reset,
        imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.events.create,
            updateApiCall: Api.events.update,
            defaultValues: { toRemember: [] },
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/events">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"title"}>Title</label>
                        <Controller
                            control={control}
                            as={<Input id={"title"} />}
                            rules={{ required: true }}
                            name="title"
                        />
                        <FieldErrorMessage errors={errors} name="title" />
                    </Col>

                </Row>
                <Row gutter={6}>
                    <Col span={12}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>
                    <Col span={12}>
                        <label htmlFor={"imageGallery"}>Image Gallery</label>
                        <Controller
                            control={control}
                            as={<FileUploader limit={10} />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="imageGallery"
                        />
                        <FieldErrorMessage errors={errors} name="imageGallery" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={3}>
                        <label htmlFor={"showInAllCountries"}>Show in all countries </label>
                        <Controller
                            control={control}
                            as={<Switch style={{ display: 'block' }} />}
                            defaultValue={false}
                            valueName="checked"
                            name="showInAllCountries"
                        />
                        <FieldErrorMessage errors={errors} name="showInAllCountries" />
                    </Col>
                    <Col span={2}>
                        <label htmlFor={"isVirtual"}>Is Virtual </label>
                        <Controller
                            control={control}
                            as={<Switch style={{ display: 'block' }} />}
                            defaultValue={false}
                            valueName="checked"
                            name="isVirtual"
                        />
                        <FieldErrorMessage errors={errors} name="isVirtual" />
                    </Col>
                    <Col span={2}>
                        <label htmlFor={"isFree"}>Is Free </label>
                        <Controller
                            control={control}
                            as={<Switch style={{ display: 'block' }} />}
                            defaultValue={true}
                            valueName="checked"
                            name="isFree"
                        />
                        <FieldErrorMessage errors={errors} name="isFree" />
                    </Col>

                    {
                        !isFree &&
                        <Col span={6}>
                            <label htmlFor={"price"}>Price</label>
                            <Controller
                                control={control}
                                as={<InputNumber style={{ width: "100%" }} id="price" />}
                                name="price"
                            />
                            <FieldErrorMessage errors={errors} name="price" />
                        </Col>
                    }
                    {
                        !isFree &&
                        <Col span={6}>
                            <label htmlFor={"currency"}>Currency</label>
                            <Controller
                                control={control}
                                as={<Input id="currency" />}
                                name="currency"
                            />
                            <FieldErrorMessage errors={errors} name="currency" />
                        </Col>
                    }

                </Row>

                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"category"}>Category</label>
                        <Controller
                            control={control}
                            as={<Select loading={categoriesLoading} style={{ width: "100%" }} id={"category"} options={categories.map(c => ({ label: c.name, value: c._id, key: c._id }))} />}
                            rules={{ required: true }}
                            name="category"
                        />
                        <FieldErrorMessage errors={errors} name="category" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"tags"}>Tags</label>
                        <Controller
                            control={control}
                            as={<TagsInput id="tags" />}
                            name="tags"
                        />
                        <FieldErrorMessage errors={errors} name="tags" />
                    </Col>
                    {
                        !isVirtual &&
                        <Col span={6}>
                            <label htmlFor={"venue"}>Venue</label>
                            <Controller
                                control={control}
                                as={<Select showSearch filterOption optionFilterProp="label" loading={venuesLoading} style={{ width: "100%" }} id={"venue"} options={venues.map((c: any) => ({ label: c.name, value: c._id, key: c._id }))} />}
                                rules={{ required: false }}
                                name="venue"
                            />
                            <FieldErrorMessage errors={errors} name="venue" />
                        </Col>
                    }

                </Row>




                <Row style={{ width: "100%" }} gutter={12}>
                    {
                        !showInAllCountries &&
                        <Col span={4}>
                            <label htmlFor={"countries"}>Countries</label>
                            <Controller
                                control={control}
                                as={<Select loading={countriesLoading} mode="multiple" style={{ width: "100%" }} id={"countries"} options={countries.map(c => ({ label: c.name, value: c._id, key: c._id }))} />}
                                rules={{ required: true }}
                                name="countries"
                            />
                            <FieldErrorMessage errors={errors} name="countries" />
                        </Col>
                    }
                    <Col span={6}>
                        <DatePickerField fieldName="publishDate" control={control} errors={errors} />
                    </Col>


                </Row>

                <ToRememberFormInput control={control} errors={errors} />

                <Row gutter={8} >
                    <Col span={12}>
                        <Card title="Coordinates">
                            <Row gutter={12}>
                                <Col span={12}>
                                    <label htmlFor={"coordinates.latitude"}>Latitude</label>
                                    <Controller
                                        control={control}
                                        as={<Input />}
                                        name="coordinates.latitude"
                                        rules={{ required: false }}
                                    />
                                    <FieldErrorMessage errors={errors} name="coordinates.latitude" />
                                </Col>
                                <Col span={12}>
                                    <label htmlFor={"coordinates.longitude"}>Longitude</label>
                                    <Controller
                                        control={control}
                                        as={<Input />}
                                        rules={{ required: false }}
                                        name="coordinates.longitude"
                                    />
                                    <FieldErrorMessage errors={errors} name="coordinates.longitude" />
                                </Col>

                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Phone Number">
                            <Row gutter={12}>
                                <Col span={8}>
                                    <label htmlFor={"phoneNumber.countryCode"}>Country Code</label>
                                    <Controller
                                        control={control}
                                        as={<Input />}
                                        rules={{ required: false }}
                                        name="phoneNumber.countryCode"
                                    />
                                    <FieldErrorMessage errors={errors} name="phoneNumber.countryCode" />
                                </Col>
                                <Col span={16} >
                                    <label htmlFor={"phoneNumber.number"}>Number</label>
                                    <Controller
                                        control={control}
                                        as={<Input />}
                                        rules={{ required: false }}
                                        name="phoneNumber.number"
                                    />
                                    <FieldErrorMessage errors={errors} name="phoneNumber.number" />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row style={{ width: "100%" }} gutter={12}>


                    <Col span={18}>
                        <DateArrayField control={control} errors={errors} fieldName="date" setError={setError} clearErrors={clearError} />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"text"}>Text</label>
                        <Controller
                            control={control}
                            as={<TextEditorInput />}
                            rules={{ required: false }}
                            name="text"
                        />
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"website"}>Website</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<GlobalOutlined />} id={"website"} />}
                            rules={{ required: false }}
                            name="website"
                        />
                        <FieldErrorMessage errors={errors} name="website" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMediaUrls.facebook"}>Facebook</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<FacebookOutlined />} id={"socialMediaUrls.facebook"} />}
                            rules={{ required: false }}
                            name="socialMediaUrls.facebook"
                        />
                        <FieldErrorMessage errors={errors} name="socialMediaUrls.facebook" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMediaUrls.instagram"}>Instagram</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<InstagramOutlined />} id={"socialMediaUrls.instagram"} />}
                            rules={{ required: false }}
                            name="socialMediaUrls.instagram"
                        />
                        <FieldErrorMessage errors={errors} name="socialMediaUrls.instagram" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMediaUrls.twitter"}>Twitter</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<TwitterOutlined />} id={"socialMediaUrls.twitter"} />}
                            rules={{ required: false }}
                            name="socialMediaUrls.twitter"
                        />
                        <FieldErrorMessage errors={errors} name="socialMediaUrls.twitter" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"socialMediaUrls.youtube"}>Youtube</label>
                        <Controller
                            control={control}
                            as={<Input prefix={<YoutubeOutlined />} id={"socialMediaUrls.youtube"} />}
                            rules={{ required: false }}
                            name="socialMediaUrls.youtube"
                        />
                        <FieldErrorMessage errors={errors} name="socialMediaUrls.youtube" />
                    </Col>

                </Row>

            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default EventForm;