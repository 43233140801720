import { Button, Col, Input, message, Result, Row, Select, Space } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useCountriesList } from "../hooks/useCountriesList";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";




export const Ad_Placements = [
    { label: 'Home Page Header', value: 'Home_Page_1' },
    { label: 'Home Page Middle', value: 'Home_Page_2' },
    { label: 'Home Page Footer', value: 'Home_Page_3' },

    { label: 'Events Page Header', value: 'Events_Page_1' },
    { label: 'Events Page Side', value: 'Events_Page_2' },
    { label: 'Events Page Side 2', value: 'Events_Page_Side_2' },
    { label: 'Events Page Footer', value: 'Events_Page_3' },

    { label: 'Articles Page Header', value: 'Articles_Page_1' },
    { label: 'Articles Page Side', value: 'Articles_Page_2' },
    { label: 'Articles Page Footer', value: 'Articles_Page_3' },

    { label: 'Events Listing Item 1', value: 'Events_Listing_Item_1' },
    { label: 'Events Listing Item 2', value: 'Events_Listing_Item_2' },

    { label: 'Articles Listing Item 1', value: 'Articles_Listing_Item_1' },
    { label: 'Articles Listing Item 2', value: 'Articles_Listing_Item_2' },
];


const AdvertisementForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/advertisements')
    }
    const imageFields = [
        {
            name: "image",
            singleValue: true,
        }
    ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.advertisements.single, id, setValue: reset,
        imageFields
    })
    const [countries, countriesLoading] = useCountriesList();
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.advertisements.create,
            updateApiCall: Api.advertisements.update,
            onSuccess,
            imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/advertisements">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"country"}>Country</label>
                        <Controller
                            control={control}
                            as={<Select style={{ width: "100%" }} showSearch optionFilterProp="label" loading={countriesLoading} options={countries.map(c => ({ label: c.name, value: c._id }))} />}
                            rules={{ required: true }}
                            name="country"
                        />
                        <FieldErrorMessage errors={errors} name="country" />
                    </Col>

                    <Col span={6}>
                        <label htmlFor={"placement"}>Placement</label>
                        <Controller
                            control={control}
                            as={<Select optionFilterProp="label" showSearch style={{ width: "100%" }} options={Ad_Placements} />}
                            rules={{ required: true }}
                            name="placement"
                        />
                        <FieldErrorMessage errors={errors} name="placement" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"url"}>URL</label>
                        <Controller
                            control={control}
                            as={<Input id="url" />}
                            rules={{ required: false }}
                            name="url"
                        />
                        <FieldErrorMessage errors={errors} name="url" />
                    </Col>

                </Row>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"image"}>Image</label>
                        <Controller
                            control={control}
                            as={<FileUploader />}
                            rules={{
                                validate: ImagesHelpers.validate
                            }}
                            name="image"
                        />
                        <FieldErrorMessage errors={errors} name="image" />
                    </Col>
                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default AdvertisementForm;