import { Badge, Descriptions, Skeleton, Tag, Image, Button, Typography, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Api } from '../api';
import { Helpers } from '../helpers';
import { ImagesHelpers } from '../helpers/images';
import "../styles/editor.css";
import "../styles/reset.css";

const EventSubmissionSingle = () => {
    let { id } = useParams();
    const [state, setState]: any = useState({ loading: true, data: {} });

    useEffect(() => {
        setState({ loading: true, data: {} });
        Api.eventSubmissions.single(id).then(res => {
            setState({ loading: false, data: res.data.data });
        })
    }, []);
    if (state.loading)
        return (<Skeleton />);

    const onAccept = () => {
        setState({ ...state, loading: true });
        Api.eventSubmissions.accept(id).then((res) => {
            setState({ ...state, loading: false, data: res.data.data });
        })
    };

    const onReject = () => {
        setState({ ...state, loading: true });
        Api.eventSubmissions.reject(id).then((res) => {
            setState({ ...state, loading: false, data: res.data.data });
        })
    }

    return (


        <div style={{ background: "white", padding: 10 }}>
            {
                state.data.status === "pending" &&
                <div style={{ display: "flex ", position: "sticky", top: 0, justifyContent: "center", padding: 5, background: "white" }}>
                    <div >
                        <Popconfirm
                            title="Are you sure to accept this submission?"
                            onConfirm={onAccept}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button style={{ marginRight: 5 }}  >Accept and save event as draft</Button>
                        </Popconfirm>

                        <Popconfirm
                            title="Are you sure to reject this submission?"
                            onConfirm={onReject}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger style={{ marginRight: 5 }}  >Reject</Button>
                        </Popconfirm>
                    </div>
                </div>
            }
            <Descriptions title={<div>
                Event Submission -  <Typography.Text mark>{state.data.status}</Typography.Text>
            </div>} bordered>
                <Descriptions.Item span={3} label="Submitted By ">{state.data.createdBy.firstName} {state.data.createdBy.lastName},
                 <a href={state.data.createdBy.email}>{state.data.createdBy.email}</a> </Descriptions.Item>
                <Descriptions.Item label="Title">{state.data.title}</Descriptions.Item>
                <Descriptions.Item label="Category">{state.data.category.name}</Descriptions.Item>

                <Descriptions.Item label="Tags">{state.data.tags && state.data.tags.map((t: any) => (<Tag key={t}  >{t}</Tag>))}</Descriptions.Item>
                <Descriptions.Item label="Venue Status">{state.data.venueStatus}</Descriptions.Item>
                <Descriptions.Item span={2} label="Venue">{state.data.venue}</Descriptions.Item>
                <Descriptions.Item span={3} label="New Venue">

                    {
                        state.data.newVenue && <div>
                            <p>Name : {state.data.newVenue.name}</p>
                            <p>Country : {state.data.newVenue?.country?.name}</p>
                            <p>Description :{state.data.newVenue.description}</p>
                            <div>
                                <p>Image:</p>
                                <Image width={200} src={ImagesHelpers.formatUrl(state.data.newVenue.image)} />
                            </div>
                            <p>Latitude :{state.data.newVenue.latitude}</p>
                            <p>Longitude :{state.data.newVenue.longitude}</p>
                            <p>Phone Numbers : {state.data.newVenue?.phoneNumbers?.map((n: any) => (`${n.countryCode}-${n.number} | `))}</p>
                        </div>
                    }

                </Descriptions.Item>
                <Descriptions.Item span={3} label="Pricing">

                    <p>Is Free: {state.data.isFree ? "Yes" : "No"}</p>
                    <p>Price: {state.data.price}</p>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Country">

                    <p>Show In All countries: {state.data.showInAllCountries ? "Yes" : "No"}</p>
                    <p>Country: {state.data.country?.name}</p>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Image">
                    <Image
                        width={200}
                        src={ImagesHelpers.formatUrl(state.data.image && state.data.image[0])}
                    />

                </Descriptions.Item>
                <Descriptions.Item span={3} label="Image Gallery">
                    {
                        state.data.image && state.data.image.map((image: string) => (
                            <Image
                                width={200}
                                key={image}
                                src={ImagesHelpers.formatUrl(image)}
                            />
                        ))
                    }

                </Descriptions.Item>

                <Descriptions.Item span={3} label="Social Media">
                    <div>
                        <p>Facebook: <a href={state.data.socialMedia && state.data.socialMedia.facebook}>
                            {state.data.socialMedia && state.data.socialMedia.facebook}</a></p>
                        <p>Instagram: <a href={state.data.socialMedia && state.data.socialMedia.instagram}>
                            {state.data.socialMedia && state.data.socialMedia.instagram}</a></p>
                        <p>Twitter: <a href={state.data.socialMedia && state.data.socialMedia.twitter}>
                            {state.data.socialMedia && state.data.socialMedia.twitter}</a></p>
                        <p>Youtube: <a href={state.data.socialMedia && state.data.socialMedia.youtube}>
                            {state.data.socialMedia && state.data.socialMedia.youtube}</a></p>
                        <p>Website: <a href={state.data.website}>{state.data.website}</a></p>
                    </div>


                </Descriptions.Item>
                <Descriptions.Item span={3} label="Dates">
                    {
                        state.data.dates && state.data.dates.map((date: any) => (
                            <p>From: {Helpers.formatDate(date.from)} | To : {Helpers.formatDate(date.to)}</p>
                        ))
                    }

                </Descriptions.Item>

                <Descriptions.Item span={3} label="Text">
                    <div className="ck-content" dangerouslySetInnerHTML={{ __html: state.data.text }} />
                </Descriptions.Item>





            </Descriptions>



        </div>
    )
};

export default EventSubmissionSingle;